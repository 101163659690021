.paginationButton {
  border: none;
  border-radius: 50%; /* Make it elliptical */
  width: 40px; /* Fixed width for all buttons */
  height: 40px; /* Fixed height for all buttons */
  background-color: white;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.paginationButton:hover {
  background-color: lightgray;
}

.paginationButton:active {
  background-color: darkgray;
  color: white;
}


/* Disabled button style */
.paginationButton:disabled {
  opacity: 0.3;
}



/*html {*/
/*  font-family: sans-serif;*/
/*  font-size: 14px;*/
/*}*/

/*table {*/
/*  border: 1px solid lightgray;*/
/*}*/

/*tbody {*/
/*  border-bottom: 1px solid lightgray;*/
/*}*/

/*th {*/
/*  border-bottom: 1px solid lightgray;*/
/*  border-right: 1px solid lightgray;*/
/*  padding: 2px 4px;*/
/*}*/

/*tfoot {*/
/*  color: rgb(128, 128, 128);*/
/*}*/

/*tfoot th {*/
/*  font-weight: normal;*/
/*}*/
